QrReader

<template>
  <v-container class="pa-0 fill-height">
    <v-bottom-sheet v-model="showCivilId" max-width="600px">
      <v-card>
        <v-card-title>
          Ingreso con identificación
          <v-spacer></v-spacer>
          <v-btn fab icon small @click="showCivilId = false; scanning = false; civilId = null">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="civilId" filled rounded label="Id o documento" required id="civilID"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-btn color="primary" text @click="showCivilId = false; scanning = false; civilId = null"
            rounded>CANCELAR</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="loginWithID" rounded :loading="loading" class="mb-4">
            <v-icon left>
              mdi-login
            </v-icon>
            CONTINUAR</v-btn>
        </v-card-actions>

      </v-card>
    </v-bottom-sheet>

  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp, serverTimestamp } from "firebase/firestore";

import { getDatabase, ref, push, set, get, child, onChildChanged, update } from "firebase/database";

import moment from 'moment';

import { logAuditEvent } from '@/error/audit.js';

export default {
  name: 'QRCodeReader',
  props: {
    usingComponentAsAnAPI: {
      type: Boolean,
      default: false
    },
    selectedSchedule: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      civilId: null,
      showCivilId: false,
      scanning: false,
      lastScanned: null,
      dialog: false,
      hideFinish: false,
      workoutsCompleted: 0,
      workoutDates: [],
      workoutDoc: null,
      workingOut: false,
      user: null,
      inscription: null,
      today: moment().format("YYYY-MM-DD"),
      alert: false,
      loading: false,
      cameras: [],
      selectedCameraIndex: 0,
      selectedCamera: null,
      facingMode: 'user',
      camera: null

    }
  },
  mounted() {
    
  },
  methods: {
    focusCivilId() {
      this.showCivilId = true;
      this.$nextTick(() => {
        document.getElementById("civilID").focus();
      })

    },
    async updateReservation(qrParsed, idReservation) {

      const db = getDatabase();
      const userid = qrParsed.userId;
      const reservationId = idReservation;

      let refer = ref(db, `${userid}/reservations/${reservationId}`)
      let snapshot = await get(refer);

      if (snapshot.exists()) {
        let reservation = snapshot.val();

        let date = reservation.date
        date = moment(date, "YYYY-MM-DD HH:mm:ss")
        let today = moment()
        let sameDate = moment(date).isSame(today, 'day')

        if (!sameDate) {
          this.$notify({
            group: 'feedback',
            title: 'Error',
            text: 'Codigo QR no válido para hoy.',
            type: 'warning'
          });
          return;
        }

        if (!reservation.used) {
          reservation.used = true;
          await update(refer, reservation);

          if (!this.showCivilId && !this.$props.usingComponentAsAnAPI) {
            this.$notify({
              group: 'feedback',
              title: 'Exito',
              text: 'Codigo QR escaneado correctamente.',
              type: 'success'
            });
          } else {
            this.$notify({
              group: 'feedback',
              title: 'Exito',
              text: 'Asistencia registrada correctamente.',
              type: 'success'
            });
          }
          this.showCivilId = false;
          if (!this.$props.usingComponentAsAnAPI) {
            this.dialog = true;
          }
        } else {
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'Codigo QR ya utilizado hoy.',
            type: 'warning'
          });
        }

      } else {
        //console.log("No data available");
      }

    },

    async inscriptAndFinishDailyWorkout(id) {
      this.hideFinish = true;
      this.workingOut = false;

      const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

      let result = await this.updateCheckIn(id, this.inscription)
      if (!result) {
        return;
      }

      // Check if already worked out today
      let found = this.workoutDates.find(elem =>
        elem.substring(0, 10) == formattedDate.substring(0, 10)
      );

      if (found) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Ya se registro asistencia hoy.',
          type: 'warning'
        });
        return;
      }

      const db = getFirestore();
      const workoutsRef = collection(db, 'workouts');

      // Add new workout
      await addDoc(workoutsRef, {
        user_id: id,
        date: serverTimestamp(),
        mainWorkout: this.user?.mainWorkout || null,
        rating: 0,
        workoutsCompleted: this.workoutsCompleted + 1
      });

      this.workoutsCompleted += 1;

      let today = moment();
      await this.setAlert('checkin_no_reservation', today.toDate(), null);

      if (this.user.plan == this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Felicitaciones',
          text: 'Plan completado!! Continua asi!!',
          type: 'success'
        });
      }

      this.showCivilId = false;
    },
    async finishDailyWorkout(id) {
      this.hideFinish = true;
      this.workingOut = false;

      const db = getFirestore();
      const workoutsRef = collection(db, 'workouts');

      // Add new workout document
      await addDoc(workoutsRef, {
        user_id: id,
        date: serverTimestamp(),
        mainWorkout: this.user?.mainWorkout || null,
        rating: 0,
        workoutsCompleted: this.workoutsCompleted + 1
      });

      this.workoutsCompleted += 1;
      await this.updateCheckIn(id, this.inscription);

      if (this.user.plan == this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Plan completado!! Continua asi!!',
          type: 'success'
        });
      }
    },
    async updateCheckIn(id, schedule) {
      const user = id
      let date;
      if (this.inscription && schedule) {
        date = moment(schedule.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")

        // Get a reference to the schedule document
        const db = getFirestore()
        let scheduleRef = doc(db, `schedule/${date}/schedules/${schedule.id}`);

        await runTransaction(db, async transaction => {
          const doc = await transaction.get(scheduleRef);
          if (!doc.exists) {
            throw "Document does not exist!";
          }
          // Decrement the spots and add thge user to the users array
          const data = doc.data();
          let userInscription = data.users.find(elem => elem.id == user)
          userInscription.checkedIn = moment().format("YYYY-MM-DD HH:mm:ss")

          transaction.update(scheduleRef, data);
        });

        return true
      } else {
        const db = getFirestore()
        let startDate;
        let q;
        let schedules;

        if (this.$props.usingComponentAsAnAPI && this.$props.selectedSchedule) {
          let scheduleDocId = this.$props.selectedSchedule.id

          if (this.$props.selectedSchedule.startDate && this.$props.selectedSchedule.startDate.seconds) {
            date = moment(this.$props.selectedSchedule.startDate.seconds * 1000).format("YYYY-MM-DD")
          } else {
            date = moment(this.$props.selectedSchedule.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
          }


          // hhere use getDOc
          const docRef = doc(db, `schedule/${date}/schedules/${scheduleDocId}`);
          const docSnap = await getDoc(docRef);
          const docData = docSnap.data()

          // map it to be in schedules array
          schedules = [{
            ...docData,
            id: docSnap.id
          }]

        } else {
          date = moment().format("YYYY-MM-DD")
          startDate = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:mm:ss")
          const schedulesRef = collection(db, `schedule/${date}/schedules`);
          q = query(schedulesRef, where("startDate", ">", startDate));
          schedules = await getDocs(q);


        }

        if (schedules.empty) {
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'No hay horarios disponibles para el día de hoy.',
            type: 'warning'
          });
          return false
        }
        let schedule;
        if (this.$props.usingComponentAsAnAPI && this.$props.selectedSchedule) {
          schedule = schedules[0]
        } else {
          schedule = {
            ...schedules.docs[0].data(),
            id: schedules.docs[0].id
          }
        }

        let userInscriptionObj = {
          id: user,
          checkedIn: moment().format("YYYY-MM-DD HH:mm:ss")
        }

        let scheduleDate;


        if (schedule.startDate && schedule.startDate.seconds) {
          scheduleDate = moment(schedule.startDate.seconds * 1000).format("YYYY-MM-DD")
        } else {

          scheduleDate = moment(schedule.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        }
        //here use a transaction to update and not updatedoc

        let scheduleRef = doc(db, `schedule/${scheduleDate}/schedules/${schedule.id}`);

        await runTransaction(db, async transaction => {
          const doc = await transaction.get(scheduleRef);
          if (!doc.exists) {
            throw "Document does not exist!";
          }
          // Decrement the spots and add thge user to the users array

          const data = doc.data();

          if (data.spots > 0) {
            data.spots--;

            //check if user is already in the array
            let userInscription = data.users.find(elem => elem.id == user)
            if (userInscription) {
              userInscription.checkedIn = moment().format("YYYY-MM-DD HH:mm:ss")
            } else {
              data.users.push(userInscriptionObj)
            }
          } else {
            this.$notify({
              group: 'feedback',
              title: 'Atención',
              text: 'No hay cupos disponibles para el siguiente horario.',
              type: 'warning'
            });

            this.setAlert('no_spots', moment().toDate(), null)

            return false
          }



          transaction.update(scheduleRef, data);

          this.$notify({
            group: 'feedback',
            title: 'Inscripción exitosa',
            text: 'Recuerda reservar para mañana!!',
            type: 'success'
          });
        });

        return true

      }

    },
    async loginWithID() {
      try {
        //Reset all important values in data
        this.lastScanned = null;
        this.dialog = false;
        this.hideFinish = false;
        this.workingOut = false;
        this.inscription = null;
        this.workoutsCompleted = 0;
        this.workoutDates = [];
        this.workoutDoc = null;
        this.user = null;
        this.inscript = null;
        this.today = moment().format("YYYY-MM-DD");
        this.alert = false;



        this.loading = true;
        let id = this.civilId.replace(/\D/g, "");
        let ok = await this.getUserById(id)
        if (!ok) {
          this.loading = false;
          return;
        }
        ok = await this.getWorkoutsCompleted(id)
        if (!ok) {
          this.loading = false;
          return;

        }
        await this.getReservationsById(id)
        this.loading = false;

        logAuditEvent('checkin', this.$store.state.Auth.token.claims.user_id, `User ${id} checked manually (reservations screen) `)


      } catch (error) {
        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User ${id} checkin failed manually (reservations screen). Error: ${error}`)
      }
    },
    async loginWithIDAPI(id) {
      try {
        // Reset state
        this.resetState();
        this.loading = true;

        // Get user data
        let ok = await this.getUserById(id);
        if (!ok) return false;

        // Get workouts data  
        ok = await this.getWorkoutsCompleted(id);
        if (!ok) return false;

        // Check reservations and handle check-in
        await this.getReservationsById(id);

        logAuditEvent('checkin', this.$store.state.Auth.token.claims.user_id, `User ${id} checked loginWithIDAPI`)

        return true;

      } catch (error) {
        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User ${id} checkin failed loginWithIDAPI. Error: ${error}`)
        return false;
      } finally {
        this.loading = false;
      }
    },
    resetState() {
      this.lastScanned = null;
      this.dialog = false;
      this.hideFinish = false;
      this.workingOut = false;
      this.inscription = null;
      this.workoutsCompleted = 0;
      this.workoutDates = [];
      this.workoutDoc = null;
      this.user = null;
      this.inscript = null;
      this.today = moment().format("YYYY-MM-DD");
      this.alert = false;
    },
    async getReservationsById(id) {
      const userid = id
      const db = getDatabase();

      let today = moment();
      let refer = ref(db, `${userid}/reservations`)
      let snapshot = await get(refer);

      let hasReservation = false;

      if (snapshot.exists()) {
        let reservations = snapshot.val();
        for (const key in reservations) {
          if (Object.hasOwnProperty.call(reservations, key)) {
            const reservation = reservations[key];
            if (moment(new Date(reservation.date.seconds * 1000), 'YYYY-MM-DD HH:mm:ss').isSame(today, 'day')) {
              this.lastScanned = reservation
              this.inscription = reservation
              hasReservation = true;
              await this.updateReservation({ userId: id }, key)
              await this.finishDailyWorkout(id)
            }
          }
        }
      } else {
        //console.log("No data available");
      }

      if (!hasReservation && this.user.plan > this.workoutsCompleted) {

        await this.inscriptAndFinishDailyWorkout(id)
      } else if (!hasReservation && this.user.plan <= this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Plan completado, no se puede registrar mas asistencias esta semana.',
          type: 'warning'
        });

        await this.setAlert('plan_completed', today.toDate(), null)
      }
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
      return endOfWeek;
    },
    async getWorkoutsCompleted(id) {
      const db = getFirestore()
      const workoutsRef = collection(db, 'workouts');
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      // Query workouts for this user in current week
      const q = query(workoutsRef,
        where("user_id", "==", id),
        where("date", ">=", startOfWeek),
        where("date", "<", endOfWeek)
      );

      const workoutDocs = await getDocs(q);

      // Get workout dates and count
      this.workoutDates = workoutDocs.docs.map(doc => {
        const data = doc.data();
        return moment(data.date.seconds * 1000).format('YYYY-MM-DD HH:mm:ss');
      });

      this.workoutsCompleted = this.workoutDates.length;

      const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

      if (this.user.plan == this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Plan completado, no se puede registrar mas asistencias.',
          type: 'warning'
        });

        await this.setAlert('plan_completed', moment().toDate(), null)
        return false;
      }

      if (this.workoutDates.length > 0 &&
        this.workoutDates[this.workoutDates.length - 1].substring(0, 10) == formattedDate.substring(0, 10)) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Ya se registro asistencia hoy.',
          type: 'warning'
        });
        return false;
      }

      this.workingOut = true;
      return true;
    },
    async getUserById(id) {
      const db = getFirestore()
      let user = id

      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'Usuario no encontrado',
          type: 'error'
        });

        this.setAlert('user_not_found', moment().toDate(), null)

        return false;
      }

      let data = docSnap.data()

      data.plan = parseInt(data.plan)



      if (typeof data.plan !== 'undefined') {
        if (parseInt(data.plan) == 0) {
          data.plan = 6
        }
      } else {
        this.setAlert('user_plan_not_found', moment().toDate(), null)
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'Usuario no tiene plan asignado. Contacte con el administrador.',
          type: 'error'
        });

        return false
      }

      data.id = docSnap.id

      this.user = data

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)
          this.$notify({
            group: 'feedback',
            title: 'Atención',
            text: 'Plan vencido, no se puede registrar asistencia. Contacte con el administrador.',
            type: 'warning'
          });
          return false;
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true
          let diff = endOfSubscription.getTime() - today.getTime();
          let days = Math.ceil(diff / (1000 * 3600 * 24));
          if (days <= 5) {
            this.alert = days;
          } else {
            this.alert = false;
          }



        }
      }


      let today = moment().toDate()

      //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
      if (this.user.licensePaused) {
        //await this.setAlert('user_license_paused', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
        });

        this.setAlert('user_license_paused', today, null)
        return false;
      }

      //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
      // Query Firestore for licenses issued before today
      const licensesRef = collection(db, `users/${user}/licenses`);
      const q = query(licensesRef, where("issuedOn", "<", today));

      const licenseDocs = await getDocs(q);
      const filteredLicenses = licenseDocs.docs
        .map(doc => doc.data())
        .filter(license => {
          if (license.resumedOn) {
            return new Date(license.resumedOn.seconds * 1000) > today;
          } else if (typeof license.resumedOn === 'undefined') {
            return true;
          }

        }); // Filter by resumedOn in client

      if (filteredLicenses.length === 0) {
        //console.log('No matching documents.');
      } else {
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
        });


        this.setAlert('user_license_paused', today, null)
        return false;
      }




      return true;
    },
    async setAlert(type, date, description) {

      try {
      const db = getFirestore();

      let userID;
      if (this.user && this.user.id) {
        userID = this.user.id;
      } else if (this.civilId) {
        userID = this.civilId;
      }

      // Don't create alert if no valid user ID
      if (!userID) {
        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Attempted to create alert without valid user ID`);
        console.warn('Attempted to create alert without valid user ID');
        return;
      }

      
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: userID,
          type: type,
          date: timestampDate,
          description: description,
          seen: false,
          path: 'QRreader'
        };

        await addDoc(collection(db, 'alerts'), newAlert);
        
        await logAuditEvent('create', this.$store.state.Auth.token.claims.user_id, 
            `Created alert type ${type} for user ${userID}`);

      } catch (error) {
        console.error("Error adding alert:", error);
        await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
            `Error creating alert type ${type} for user ${userID}: ${error}`);
      }
    },

  }
}
</script>

<style scoped>
.v-input {
  flex: none !important;
}
</style>

<style>
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.qrcode-stream-camera {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
