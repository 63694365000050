<template>
    <v-container>

        <v-tabs v-model="tab" class="rounded-lg overflow-hidden" grow>
            <v-tab>
                <v-icon left>
                    mdi-calendar-month-outline
                </v-icon>
                Reservas
            </v-tab>

            <v-tab v-if="evaluations.length > 0">

                <v-badge small :color="checkIfEvaluated" :content="evaluations.length" :value="evaluations.length"
                    overlap left id="badgeEval">
                    <v-icon left>
                        mdi-account-edit-outline
                    </v-icon>
                    Evaluaciones

                </v-badge>
            </v-tab>


            <v-tab-item>
                <v-divider />
                <v-menu max-width="300" offset-y
                    v-if="($store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'superuser')">

                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="ma-2 rounded-lg" hide-details="" dense v-model="date"
                            label="Seleccionar fecha" readonly v-on="on" filled rounded v-bind="attrs"></v-text-field>
                    </template>
                    <v-date-picker width="100%" :max="maxDate" v-model="date" @input="fetchSchedules"
                        color="primary"></v-date-picker>
                </v-menu>
                <v-row no-gutters>

                    <v-col cols="12" v-if="loading">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6" md="3">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6" md="3">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6" md="3">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6" md="3">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6" md="3">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6" md="3">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col>
                        </v-row>

                    </v-col>




                    <v-col cols="6" md="3" class="pa-2 pl-2 pr-2" v-for="schedule in schedules" :key="schedule.id">


                        <v-card class="rounded-lg overflow-hidden " @click="showSchedule(schedule)" rounded="0"
                            :color="getScheduleColor(schedule)">

                            <v-card-title>

                                {{ formatToHourMinutes(schedule.startDate)
                                    + 'hs' }}



                                <v-spacer></v-spacer>

                                <v-icon left>
                                    mdi-account-multiple
                                </v-icon> {{ schedule.users.length }}



                            </v-card-title>



                            <!--  <v-card-subtitle class="py-2">
                                {{ schedule.type }}
                            </v-card-subtitle> -->

                            <v-card-text class="caption">
                                {{ getScheduleStatus(schedule) == 'ongoing' ? 'EN CURSO' : scheduleText(schedule) }}


                            </v-card-text>


                        </v-card>
                    </v-col>

                    <v-col cols="12" v-if="!loading && schedules.length == 0">
                        <v-alert color="primary darken-3" type="info" class="ma-4">
                            No hay horarios disponibles. <br> Estamos cerrados.
                        </v-alert>
                    </v-col>



                </v-row>





            </v-tab-item>

            <v-tab-item v-if="evaluations.length > 0">
                <v-divider />
                <v-card outlined class="elevation-0 ma-2" v-for="evaluation in evaluations" :key="evaluation.doc_id"
                    @click="setEvaluation(evaluation)">

                    <v-card-title class=" mt-2 py-0 text-center">
                        <v-icon left>
                            mdi-clock-outline
                        </v-icon>
                        {{ getHHMM(evaluation) }}hs
                        <v-spacer></v-spacer>
                        <v-chip outlined :color="evaluation.evaluatedBy ? 'success' : 'error'">

                            {{ evaluation.evaluatedBy ? 'Completada' : 'Pendiente' }}
                        </v-chip>


                    </v-card-title>
                    <v-card-text>

                    </v-card-text>


                    <v-card-actions class="pt-0 pl-4">

                        <v-icon left>
                            mdi-account-outline
                        </v-icon>
                        {{ evaluation.displayName }}

                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded @click="setEvaluation(evaluation)">
                            <v-icon right>
                                mdi-pencil-outline
                            </v-icon>
                            Evaluar
                        </v-btn>
                    </v-card-actions>

                </v-card>


                <v-dialog v-model="dialogs.evaluation" max-width="500px" fullscreen persistent>
                    <v-card class="rounded-0">
                        <v-card-title>
                            <span class="text-h5">Evaluación
                            </span> <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.evaluation = false;">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="evaluationForm">

                                <Preferences ref="evaluationPreferences" :isCoachView="true" :isEvaluationView="true"
                                    v-if="selectedUser && dialogs.evaluation" :user="selectedUser"
                                    :key="selectedUser.id + 'Preferences'" />

                                <h3 class="text-h6 my-4">Datos Base</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.altura" label="Altura" hint="Opcional" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.peso" label="Peso" hint="Opcional" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>



                                <v-text-field v-model="evaluation.nivelActividadFisica"
                                    label="Nivel de actividad física" filled rounded></v-text-field>
                                <v-textarea v-model="evaluation.objetivosCortoPlazo" label="Objetivos a corto plazo"
                                    filled rounded></v-textarea>
                                <v-textarea v-model="evaluation.objetivosLargoPlazo" label="Objetivos a largo plazo"
                                    filled rounded></v-textarea>

                                <h3 class="text-h6 mb-4">Evaluación de Movimiento - GLOBAL</h3>
                                <v-text-field v-model="evaluation.extension" label="Extensión" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.flexion" label="Flexión" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.rotacion" label="Rotacion" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.izquierdaGlobal" label="Izquierda" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.derechaGlobal" label="Derecha" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.sentadillaOH" label="Sentadilla OH" filled
                                    rounded></v-text-field>
                                <v-textarea v-model="evaluation.observacionesGlobal" label="Observaciones" filled
                                    rounded></v-textarea>

                                <h3 class="text-h6 mb-4">Analíticas</h3>
                                <v-text-field v-model="evaluation.respiracionDiafragmatica"
                                    label="Respiración diafragmática" filled rounded></v-text-field>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.capacidadTotal" label="Capacidad total" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.capacidadResidual" label="Capacidad residual"
                                            filled rounded></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.scSuperior" label="SC superior" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.scInferior" label="SC inferior" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>



                                <v-text-field v-model="evaluation.sobrepronacion" label="Sobrepronacion" filled
                                    rounded></v-text-field>

                                <h3 class="mb-2 text-center">Movilidad cadera pierna recta</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.movilidadCaderaPiernaRectaIzquierda"
                                            label="Izquierda" filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.movilidadCaderaPiernaRectaDerecha"
                                            label="Derecha" filled rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Test Thomas</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.testThomasIzquierda" label="Izquierda" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.testThomasDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Activación glúteo</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.activacionGluteoIzquierda" label="Izquierda"
                                            filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.activacionGluteoDerecha" label="Derecha"
                                            filled rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Rotacion interna</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionInternaIzquierda" label="Izquierda"
                                            filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionInternaDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Hombros</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.hombrosIzquierda" label="Izquierda" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.hombrosDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Rotacion dorsal</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionDorsalIzquierda" label="Izquierda"
                                            filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionDorsalDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Dorsiflexion tobillo</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.dorsiflexionTobilloIzquierda"
                                            label="Izquierda" filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.dorsiflexionTobilloDerecha" label="Derecha"
                                            filled rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-textarea v-model="evaluation.resumen" label="Resumen" filled rounded></v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="pb-4">
                            <v-btn color="primary" :disabled="loading" text
                                @click="dialogs.evaluation = false">Volver</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" large :loading="loading" @click="submitEvaluation">
                                <v-icon left>
                                    mdi-check
                                </v-icon>
                                {{ evaluation.evaluatedBy ? 'Actualizar' : 'Guardar' }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-tab-item>

        </v-tabs>


        <!-- Users List Modal -->
        <v-bottom-sheet v-model="dialog" max-width="600px">
            <v-card v-if="selectedSchedule">

                <v-toolbar flat class="pl-4 text-h6 mb-6">
                    Reservas {{ formatToHourMinutes(selectedSchedule.startDate)
                    }}
                    <v-spacer></v-spacer>
                    <v-btn icon color="error" class="mr-2"
                        v-if="$store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'superuser'"
                        @click="deleteSchedule">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>


                <v-card-text class="mt-2 pb-2 px-2">

                    <v-row v-if="loadingDisplayNames" no-gutters>
                        <v-col cols="12">
                            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                        </v-col>
                    </v-row>

                    <v-row v-if="selectedSchedule.users.length == 0">
                        <v-col cols="12">
                            <v-alert color="primary darken-3">
                                No hay reservas para este horario
                            </v-alert>
                        </v-col>
                    </v-row>


                    <v-row v-if="!loadingDisplayNames && selectedSchedule.users.length > 0" no-gutters>
                        <v-col cols="12" md="6">
                            <v-list class="py-0" style="max-height:55vh; overflow-y: scroll;" rounded dense>
                                <v-list-item v-for="user in selectedSchedule.users" :key="user.id" v-if="user.checkedIn"
                                    class="pa-0">
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title>
                                            <v-chip @click="openUserImage(user)" style="text-transform: capitalize;">
                                                <v-avatar left v-if="user.workoutsCompleted" color="success" text
                                                    size="20" style="color:#FFF">
                                                    {{
                                                        user.workoutsCompleted
                                                    }}
                                                </v-avatar>


                                                <template v-if="userBirthDateCache[user.id]">
                                                    <v-icon small left color="yellow lighten-3">
                                                        mdi-cake-variant
                                                    </v-icon>
                                                    {{ userBirthDateCache[user.id] }}
                                                </template>

                                                <template v-if="injuryCache[user.id]">
                                                    <v-icon small left :color="injuryCache[user.id] == 1 ? 'red lighten-3' : 'green lighten-3'
                                                        ">
                                                        mdi-medical-bag
                                                    </v-icon>
                                                </template>

                                                {{ user.displayName ?
                                                    getFormattedDisplayName(user.displayName) :
                                                    user.id }}


                                                <v-spacer></v-spacer>

                                                <template
                                                    v-if="userCache[user.id] && userCache[user.id].mainWorkout && userCache[user.id].mainWorkout.plan_id">
                                                    <v-chip small color="secondary">

                                                        {{
                                                            planNamesCache[userCache[user.id].mainWorkout.plan_id]
                                                        }}
                                                    </v-chip>
                                                </template>

                                            </v-chip>



                                        </v-list-item-title>



                                    </v-list-item-content>

                                </v-list-item>

                            </v-list>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-list class="py-0" style="max-height:55vh; overflow-y: scroll;">
                                <v-list-item dense v-for="user in selectedSchedule.users" :key="user.id" class="pa-0"
                                    v-if="!user.checkedIn">
                                    <v-list-item-content class="py-0 ">
                                        <v-chip @click="openSheet(user)" style="text-transform: capitalize;">
                                            <v-icon left>
                                                mdi-checkbox-blank-circle-outline
                                            </v-icon>

                                            <template v-if="userBirthDateCache[user.id]">
                                                <v-icon color="yellow lighten-3">
                                                    mdi-cake-variant
                                                </v-icon>
                                                {{ userBirthDateCache[user.id] }} -
                                            </template>

                                            <template v-if="injuryCache[user.id]">
                                                <v-icon left :color="injuryCache[user.id] == 1 ? 'red lighten-3' : 'green lighten-3'
                                                    ">
                                                    mdi-medical-bag
                                                </v-icon>
                                            </template>

                                            {{ user.displayName ?
                                                getFormattedDisplayName(user.displayName) :
                                                user.id }}

                                            <v-spacer></v-spacer>

                                            <template
                                                v-if="userCache[user.id] && userCache[user.id].mainWorkout && userCache[user.id].mainWorkout.plan_id">
                                                <v-chip small color="secondary">
                                                    {{
                                                        planNamesCache[userCache[user.id].mainWorkout.plan_id]
                                                    }}
                                                </v-chip>
                                            </template>
                                        </v-chip>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>

                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn large rounded color="primary darken-1" @click="openIdModal" class="mb-4"
                        v-if="!disableNoActualDay">

                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        Agregar sin reserva
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="sheetAction" max-width="600px">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheetAction = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </v-card-title>

                <v-card-text class="text-center" v-if="selectedUser">
                    <div class="d-flex flex-column align-center">
                        <v-avatar size="100" class="mb-2">
                            <UserImage :user="selectedUser"></UserImage>
                        </v-avatar>
                        <span class="text-h6">{{ selectedUser.displayName }}</span>
                    </div>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="success" x-large block @click="checkAssistance" :loading="loadingAssistance"
                                v-if="!disableNoActualDay">
                                <v-icon left>mdi-check</v-icon>
                                Marcar como asistido</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

        <QRreader :selectedSchedule="selectedSchedule" :usingComponentAsAnAPI="true" ref="qrreader"></QRreader>


        <v-dialog v-model="userImage" persistent max-width="500">



            <v-card v-if="selectedUser" class="rounded-0 ">
                <v-toolbar flat dense class="pl-2">


                    <v-toolbar-title>


                        {{ selectedUser.displayName }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="userImage = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class=" text-center pa-0">

                    <v-tabs grow v-model="tabs">


                        <v-tab>
                            Perfil
                        </v-tab>
                        <v-tab>

                            Objetivos
                        </v-tab>

                        <v-tab
                            v-if="userCache[selectedUser.id] && userCache[selectedUser.id].mainWorkout && userCache[selectedUser.id].mainWorkout.plan_id">
                            Plan
                        </v-tab>



                        <v-tab-item :transition="false" class="pa-2">
                            <v-avatar size="100">
                                <UserImage :user="selectedUser"></UserImage>
                            </v-avatar>
                            <!--                             <span class="text-h6"> {{ selectedUser.displayName }}</span>
 -->
                            <PlanProgress :userObject="selectedUser" :key='selectedUser.id'></PlanProgress>

                            <v-btn block color="primary darken-3" class="rounded-lg mt-4" rounded large
                                @click="openProgress(selectedUser)">
                                <v-icon left class="mr-4">
                                    mdi-finance
                                </v-icon>
                                Ver progreso
                            </v-btn>
                        </v-tab-item>

                        <v-tab-item :transition="false" class="pa-2">
                            <Preferences :isCoachView="true" :user='userCache[selectedUser.id]'
                                :key="selectedUser.id + 'Preferences'" />
                        </v-tab-item>


                        <v-tab-item class="pa-0" :transition="false" v-if="userImage">
                            <PlanView :key="selectedUser.id" :UserObject="userCache[selectedUser.id]">
                            </PlanView>
                        </v-tab-item>


                    </v-tabs>

                </v-card-text>



            </v-card>
        </v-dialog>
        <v-dialog v-model="userProgress" fullscreen>
            <v-card v-if="selectedUser && selectedUser.displayName">
                <v-toolbar flat>
                    <v-btn icon @click="userProgress = false; selectedUser = null; userImage = false;">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-avatar size="50" class="mr-2">
                        <UserImage :small="true" :user="selectedUser" class="mx-2"></UserImage>
                    </v-avatar>
                    {{ selectedUser.displayName }}
                </v-toolbar>
                <v-card-text class="pa-0">
                    <Weights :user="selectedUser"></Weights>
                </v-card-text>
            </v-card>



        </v-dialog>
    </v-container>
</template>

<script>

import { collection, getDocs, getDoc, onSnapshot, query, where, orderBy, startAt, endAt, getFirestore, setDoc, doc, addDoc, updateDoc, Timestamp, deleteDoc } from "firebase/firestore";
import { getDatabase, ref as dbRef, get, update } from "firebase/database";

import moment from 'moment';

import QRreader from '@/components/QRreader.vue';

import UserImage from '@/components/profile/UserImage.vue';
import Preferences from '@/components/training/Preferences.vue';
const db = getFirestore();

// import Weights, PlanProgress components

import Weights from '@/components/Weights.vue';
import PlanProgress from '@/components/PlanProgress.vue';

import User from "@/models/user.js";
import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)


import PlanView from '@/views/PlanView.vue';


export default {
    beforeDestroy() {
        if (this.unsubscribeScheduleListener) {
            this.unsubscribeScheduleListener();
        }
        if (this.unsubscribeUserListener) {
            this.unsubscribeUserListener();
        }
    },

    components: {
        QRreader, UserImage, Weights, PlanProgress, Preferences, PlanView
    },
    computed: {
        returnTodayFormatted() {
            return moment().locale('es').format('LL');
        },
        checkIfEvaluated() {
            // iterate all this.evaluations and check if all are evaluated or not
            let evaluated = true;
            this.evaluations.forEach(evaluation => {
                if (!evaluation.evaluatedBy) {
                    evaluated = false;
                }
            });

            return evaluated ? 'success' : 'error';
        }
    },
    data() {
        return {
            userProgress: false,
            tab: 0,
            tabs: 0,
            userImage: false,
            schedules: [],
            selectedSchedule: null,
            dialog: false,
            loading: false,
            loadingDisplayNames: true,
            scheduleStatus: "", // New property to keep track of the schedule's status
            sheetAction: false,
            selectedUserId: null,
            selectedUser: null,
            loadingAssistance: false,
            // Other data properties
            unsubscribeScheduleListener: null,
            unsubscribeUserListener: null,
            userDisplayNameCache: {},
            userBirthDateCache: {},
            userWorkoutsCompletedCache: {},
            userPlanCache: {},
            planNamesCache: {}, // New cache for plan names
            //data for the second tab
            evaluations: [],
            dialogs: {
                evaluation: false
            },
            evaluation: {},
            selectedEvaluation: null,
            date: moment().format('YYYY-MM-DD'),
            disableNoActualDay: false,
            maxDate: moment().add(4, 'days').format('YYYY-MM-DD'),
            injuryCache: {},
            userCache: {},
            workoutsCache: {}
        };
    },

    mounted() {
        this.$vuetify.goTo(0);

        this.fetchSchedules();

        this.fetchEvaluations();
    },
    methods: {
        formatToHourMinutes(date) {

            if (date && date.seconds) {
                return moment(date.seconds * 1000).format('HH:mm');
            }

            return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
        },
        openSheet(user) {
            this.selectedUserId = null;
            this.selectedUser = null

            this.sheetAction = true;
            this.selectedUserId = user.id;
            this.selectedUser = user;
        },
        openUserImage(user) {
            this.selectedUser = null;
            this.selectedUser = user;
            this.userImage = true;
        },

        scheduleText(schedule) {
            let date = schedule.startDate;
            let dateMoment = moment(new Date(date.seconds * 1000), 'YYYY-MM-DD HH:mm:ss');
            let checkToday = moment().isSame(dateMoment, 'day');

            if (checkToday && moment().isAfter(dateMoment)) {
                return 'Hace ' + dateMoment.locale('es').fromNow(true);
            } else {
                return 'En ' + dateMoment.locale('es').fromNow(true);
            }
        },

        getScheduleColor(schedule) {
            const status = this.getScheduleStatus(schedule);

            let colorTheme = this.$store.state.isDark

            if (colorTheme) {
                switch (status) {
                    case "started":
                        return "primary darken-4"; // Color for schedules that have not started
                    case "ongoing":
                        return "primary darken-1"; // Color for ongoing schedules
                    case "finished":
                        return "grey darken-3"; // Color for finished schedules
                    default:
                        return "primary darken-3"; // Default color
                }
            } else {
                switch (status) {
                    case "started":
                        return "primary"; // Color for schedules that have not started
                    case "ongoing":
                        return "primary lighten-1"; // Color for ongoing schedules
                    case "finished":
                        return "grey lighten-3"; // Color for finished schedules
                    default:
                        return "primary lighten-3"; // Default color
                }
            }

        },

        getScheduleStatus(schedule) {
            let date = schedule.startDate;
            let dateMoment = moment(new Date(date.seconds * 1000), 'YYYY-MM-DD HH:mm:ss');
            let checkToday = moment().isSame(dateMoment, 'day');

            let dateWithDuration = moment(new Date(date.seconds * 1000), 'YYYY-MM-DD HH:mm:ss').add(schedule.duration, 'minutes');

            if (checkToday && moment().isAfter(dateWithDuration)) {
                return "finished"; // Schedule has already passed
            } else if (moment().isAfter(dateMoment) && moment().isBefore(dateWithDuration)) {
                return "ongoing";  // Schedule is today but hasn't passed yet
            } else {
                return "started";  // Schedule is in the future
            }
        },

        fetchSchedules() {
            //clear the cache
            this.userDisplayNameCache = {};
            this.userBirthDateCache = {};
            this.userWorkoutsCompletedCache = {};
            this.userPlanCache = {};
            this.userCache = {};

            this.loading = true;
            const today = this.date;

            let now = moment()
            let todayMoment = moment(today, 'YYYY-MM-DD');
            let checkToday = now.isSame(todayMoment, 'day');

            if (checkToday) {
                this.disableNoActualDay = false;
            } else {
                this.disableNoActualDay = true;
            }



            const schedulesRef = collection(db, `schedule/${today}/schedules`);

            this.unsubscribeScheduleListener = onSnapshot(schedulesRef, (snapshot) => {
                this.schedules = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                this.loading = false;
            });

            logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Read schedules for ${today}`);
        },


        async getUserDisplayName(userId) {
            try {
                const userRef = doc(db, `users/${userId}`);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    return { displayName: userId + " Usuario no encontrado" };
                }

                const userData = userSnap.data();
                userData.id = userId;

                const birthDate = userData.birthDate;
                const birthDateMoment = moment(birthDate, 'YYYY-MM-DD');
                const age = moment().diff(birthDateMoment, 'years');

                const today = moment();
                if (today.date() === birthDateMoment.date() && today.month() === birthDateMoment.month()) {
                    this.$set(this.userBirthDateCache, userId, age);
                }

                if (userData.injuries && userData.injuries.length > 0) {
                    const hasActiveInjury = userData.injuries.some((injury) => !injury.recuperado);
                    this.$set(this.injuryCache, userId, hasActiveInjury ? 1 : 2);
                }

                return userData;
            } catch (error) {
                console.error("Error fetching user display name:", error);
                return { displayName: userId + " Error al obtener datos del usuario" };
            }
        },

        showSelectedScheduleUsers() {
            if (!this.selectedSchedule || !this.selectedSchedule.id) return;

            const today = this.date;
            const scheduleDocRef = doc(db, `schedule/${today}/schedules`, this.selectedSchedule.id);

            if (this.unsubscribeUserListener) {
                this.unsubscribeUserListener();
            }

            this.unsubscribeUserListener = onSnapshot(scheduleDocRef, async (docSnapshot) => {
                if (!docSnapshot.exists()) {
                    console.log("No such document!");
                    return;
                }

                const scheduleData = docSnapshot.data();
                if (scheduleData.users && Array.isArray(scheduleData.users)) {
                    this.loadingDisplayNames = true;

                    try {
                        // Get all user IDs that need to be fetched
                        const userIdsToFetch = scheduleData.users
                            .filter(user => !this.userDisplayNameCache[user.id])
                            .map(user => user.id);

                        // Batch fetch users if needed
                        if (userIdsToFetch.length > 0) {
                            await this.fetchUsersInBatch(userIdsToFetch);
                        }

                        // Get workouts completed for checked-in users
                        const checkedInUsers = scheduleData.users.filter(user => user.checkedIn);
                        await Promise.all(
                            checkedInUsers.map(async (user) => {
                                if (!this.userWorkoutsCompletedCache[user.id]) {
                                    this.userWorkoutsCompletedCache[user.id] = await this.getWorkoutsCompleted(user.id);
                                }
                            })
                        );

                        // Map users with cached data
                        const usersWithNames = scheduleData.users.map(user => ({
                            ...user,
                            displayName: this.userDisplayNameCache[user.id],
                            workoutsCompleted: this.userWorkoutsCompletedCache[user.id],
                            plan: this.userPlanCache[user.id] || 0
                        }));

                        // Sort users
                        usersWithNames.sort((a, b) => {
                            if (a.checkedIn && !b.checkedIn) return -1;
                            if (!a.checkedIn && b.checkedIn) return 1;
                            return a.displayName.localeCompare(b.displayName);
                        });

                        this.selectedSchedule.users = usersWithNames;
                    } catch (error) {
                        console.error("Error processing users:", error);
                    } finally {
                        this.loadingDisplayNames = false;
                    }
                }
            });
        },






        successCheckIn(userId) {
            const index = this.selectedSchedule.users.findIndex(user => user.id == userId);
            this.selectedSchedule.users[index].checkedIn = true;
        },


        async checkAssistance() {
            try {
                this.loadingAssistance = true;

                // Call QR reader's check-in function
                let result = await this.$refs.qrreader.loginWithIDAPI(this.selectedUserId);

                if (result) {

                    this.sheetAction = false;
                }

            } catch (e) {
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error checking in user ${this.selectedUserId}  checkAssistance. Error: ${e} `);

                if (e.code === "unavailable" || e.code === "auth/network-request-failed") {
                    this.$notify({
                        group: "feedback",
                        duration: 5000,
                        type: "error",
                        title: "Sin conexión",
                        text: "No se pudo conectar con el servidor, intente más tarde",
                    });
                }
            } finally {
                this.loadingAssistance = false;
            }
        },
        showSchedule(schedule) {
            this.selectedSchedule = schedule;
            this.loadingDisplayNames = true;

            // Start listening for real-time updates on users for the selected schedule
            this.showSelectedScheduleUsers();

            this.dialog = true;
        },
        openIdModal() {
            this.$refs.qrreader.focusCivilId();
        },

        async fetchEvaluations() {
            const evaluationsRef = collection(db, `evaluationsHistory`);

            const todayStart = moment().startOf('day').toDate();
            const todayEnd = moment().endOf('day').toDate();

            const q = query(evaluationsRef, where('date', '>=', todayStart), where('date', '<=', todayEnd), orderBy('date', 'desc'));

            const querySnapshot = await getDocs(q);

            this.evaluations = querySnapshot.docs.map(doc => ({ ...doc.data(), doc_id: doc.id }));

            //get the user display name
            this.evaluations.forEach(async (evaluation, index) => {
                const userRef = doc(db, `users/${evaluation.id}`);
                const userSnap = await getDoc(userRef);
                const user = userSnap.data();
                this.evaluations[index].displayName = user.displayName;
                this.evaluations[index].userData = { ...user };
            });


        },

        setEvaluation(evaluation) {
            this.selectedEvaluation = null;

            this.selectedEvaluation = evaluation;
            this.dialogs.evaluation = true;


            this.selectedUser = evaluation.userData;

            if (evaluation.evaluatedBy) {
                this.evaluation = evaluation.result;
            }
        },

        async submitEvaluation() {

            const evaluation = {
                ...this.evaluation,
                date: Timestamp.fromDate(moment().toDate()),
                evaluatedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email
            };

            try {
                this.loading = true;

                // add to the user evaluations collection
                const db = getFirestore();
                delete evaluation['date']
                // update to the evaluations history collection inside result attribute
                await updateDoc(doc(db, "evaluationsHistory", this.selectedEvaluation.doc_id), {
                    result: evaluation,
                    evaluatedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email,
                    evaluatedDate: Timestamp.fromDate(moment().toDate())
                });

                // update the local evaluations array
                const index = this.evaluations.findIndex(e => e.doc_id == this.selectedEvaluation.doc_id);
                this.evaluations[index].evaluatedBy = this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email;
                this.evaluations[index].result = evaluation;

                this.dialogs.evaluation = false;
                this.selectedEvaluation = null;
                // show success notification
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "success",
                    title: "Evaluación",
                    text: "Evaluación completada",
                });


                let resp = await this.$refs['evaluationPreferences'].updateUserData()

                await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Evaluation ${this.selectedEvaluation.doc_id} updated data ${JSON.stringify(evaluation)}`);

            } catch (e) {

                await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error updating evaluation ${this.selectedEvaluation.doc_id} data ${JSON.stringify(evaluation)} error ${e}`);

                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Evaluación",
                    text: "Error al completar la evaluación",
                });
            } finally {
                this.loading = false;
            }
        },


        getHHMM(evaluation) {



            return moment(evaluation.date.toDate()).format('HH:mm');
        },

        openProgress(user) {
            this.userProgress = true;
        },

        getStartOfWeek() {
            const now = moment(this.date, 'YYYY-MM-DD').toDate();
            let day = now.getDay();
            const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
            const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
            return startOfWeek;
        },
        getEndOfWeek() {
            const startOfWeek = this.getStartOfWeek();
            const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
            return endOfWeek;
        },

        async getWorkoutsCompleted(user) {
            const cacheKey = `${user}_${this.date}`;

            // Return cached value if exists
            if (this.workoutsCache[cacheKey]) {
                return this.workoutsCache[cacheKey];
            }

            const db = getFirestore()
            const workoutsRef = collection(db, `workouts`);
            const startOfWeek = this.getStartOfWeek();
            const endOfWeek = this.getEndOfWeek();

            const q = query(workoutsRef,
                where("user_id", "==", user),
                where("date", ">=", startOfWeek),
                where("date", "<", endOfWeek)
            );

            const workoutDocs = await getDocs(q);
            const workoutsCompleted = workoutDocs.size;

            // Cache the result
            this.workoutsCache[cacheKey] = workoutsCompleted;

            return workoutsCompleted;
        },
        getFormattedDisplayName(displayName) {
            // this method must split the displayName with " " and return the first two words but before delete all the items that are empty strings
            let displayNameArray = displayName.split(" ").filter(item => item !== "");
            let formattedDisplayName = displayNameArray.slice(0, 3).join(" ");
            console.log(formattedDisplayName.toLowerCase());
            return formattedDisplayName.toLowerCase();

        },
        async fetchUsersInBatch(userIds) {
            try {
                // Get unique IDs
                const uniqueIds = [...new Set(userIds)];

                // Batch get all user docs
                const userRefs = uniqueIds.map(id => doc(db, `users/${id}`));
                const userDocs = await Promise.all(userRefs.map(ref => getDoc(ref)));

                // Collect all unique plan IDs
                const planIds = new Set();

                // Process and cache all user data at once
                userDocs.forEach((doc) => {
                    if (doc.exists()) {
                        const userData = doc.data();
                        if (userData.mainWorkout && userData.mainWorkout.plan_id) {
                            planIds.add(userData.mainWorkout.plan_id);
                        }
                    }
                });

                // Fetch all plan names in batch if needed
                if (planIds.size > 0) {
                    const planRefs = Array.from(planIds).map(id => doc(db, `planifications/${id}`));
                    const planDocs = await Promise.all(planRefs.map(ref => getDoc(ref)));

                    planDocs.forEach(doc => {
                        if (doc.exists()) {
                            this.planNamesCache[doc.id] = doc.data().name;
                        }
                    });
                }

                // Process and cache all user data at once
                userDocs.forEach((doc, index) => {
                    if (doc.exists()) {
                        const userId = uniqueIds[index];
                        const userData = doc.data();

                        // Cache all relevant user data
                        this.userDisplayNameCache[userId] = userData.displayName;
                        this.userPlanCache[userId] = userData.mainWorkout?.plan_id || null;
                        this.userCache[userId] = userData;

                        // Handle birth date
                        if (userData.birthDate) {
                            const birthDateMoment = moment(userData.birthDate, 'YYYY-MM-DD');
                            const age = moment().diff(birthDateMoment, 'years');
                            const today = moment();
                            if (today.date() === birthDateMoment.date() && today.month() === birthDateMoment.month()) {
                                this.userBirthDateCache[userId] = age;
                            }
                        }

                        // Handle injuries
                        if (userData.injuries && userData.injuries.length > 0) {
                            const hasActiveInjury = userData.injuries.some((injury) => !injury.recuperado);
                            this.injuryCache[userId] = hasActiveInjury ? 1 : 2;
                        }
                    }
                });
            } catch (error) {
                console.error("Error batch fetching users:", error);
            }
        },
        async deleteSchedule() {
            try {
                if (!this.selectedSchedule || !this.selectedSchedule.id) return;

                // Show confirmation dialog
                let confirm = await this.$confirm(
                    "Seguro que quieres eliminar este horario",
                    {
                        color: '',
                        title: "Eliminar horario",
                        buttonTrueText: "Eliminar",
                        buttonFalseText: "Cancelar"
                    }
                );
                if (!confirm) return;

                this.loading = true;
                const today = this.date;

                // Delete the schedule document
                const scheduleRef = doc(db, `schedule/${today}/schedules`, this.selectedSchedule.id);
                await deleteDoc(scheduleRef);

                // Close the dialog and clear selection
                this.dialog = false;
                this.selectedSchedule = null;

                // Show success notification
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "success",
                    title: "Horario eliminado",
                    text: "El horario ha sido eliminado correctamente",
                });

                await logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `Schedule ${this.selectedSchedule.id} deleted`);

            } catch (e) {
                console.error("Error deleting schedule:", e);
                await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error deleting schedule ${this.selectedSchedule.id}: ${e}`);

                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Error",
                    text: "No se pudo eliminar el horario",
                });
            } finally {
                this.loading = false;
            }
        },
        async setAlert(type, date, description) {
            const db = getFirestore();

            let userID;
            if (this.selectedUser && this.selectedUser.id) {
                userID = this.selectedUser.id;
            } else if (this.user && this.user.id) {
                userID = this.user.id;
            } else if (this.civilId) {
                userID = this.civilId;
            }

            // Don't create alert if no valid user ID
            if (!userID) {
                console.warn('Attempted to create alert without valid user ID');
                return;
            }

            try {
                const timestampDate = Timestamp.fromDate(date);

                const newAlert = {
                    user_id: userID,
                    type: type,
                    date: timestampDate,
                    description: description,
                    seen: false,
                    path: 'ScheduleView'
                };

                await addDoc(collection(db, 'alerts'), newAlert);

                logAuditEvent('create', this.$store.state.Auth.token.claims.user_id,
                    `Created alert type ${type} for user ${userID}`);

            } catch (error) {
                console.error("Error adding alert:", error);
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error creating alert type ${type} for user ${userID}: ${error}`);
            }
        }
    }
};
</script>

<style scoped>
.v-chip {
    display: grid !important;
}
</style>
